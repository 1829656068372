import styles from './footer.module.css'
import ScrollLink from '../scrollLink';
import React from "react";

export default function AppFooter() {
    // const [fixToBottom, setFixToBottom] = useState(false);
    // useEffect(() => {
    //     const root = document.getElementById('root')
    //     if (window.innerHeight > root.offsetHeight) setFixToBottom(true)
    // })
    return (
        // <div id="footer" className={(fixToBottom) ? `${styles.container} ${styles.containerFixed}` : `${styles.container}`}>
        <div id="footer" className={styles.container}>
            <div className={styles.footerInfoRow}>
                <div className={styles.footerLinks}>
                    <div className={styles.footerLinksSection}>
                        <ScrollLink scrollTo="header" className={styles.footerLink}>
                            О сайте
                        </ScrollLink>
                        <a href={"/docs/Polzovatelskoe_soglashenie_MK.doc"} className={`${styles.footerLink} ${styles.footerLinkAfterFirst}`}>Пользовательское соглашение </a>

                        <a href={"/docs/Politika_v_oblasti_obrabotki_PD_MK.doc"} className={`${styles.footerLink} ${styles.footerLinkAfterFirst}`}> Политика
                            конфиденциальности </a>
                        <a href={"mailto:admin@doctoronboard.ru"} className={`${styles.footerLink} ${styles.footerLinkAfterFirst}`}> admin@doctoronboard.ru</a>
                    </div>
                    <div className={styles.footerInfoSection}>
                        Сайт DoctorOnBoard предназначен исключительно для работников здравоохранения, имеющих высшее медицинское образование. Зарегистрировавшись на сайте, Вы подтверждаете, что являетесь работником здравоохранения с высшим медицинским образованием, что Вы ознакомились с текстом пользовательского соглашения и поняли его.
                    </div>
                </div>
                <div className={styles.footerRightContainer}>
                    <div className={styles.footerAgeRestriction}>
                        18+
                    </div>
                </div>
            </div>
        </div >
    )
}

