import React, { useState, useEffect, useRef } from "react";
import { phoneMask } from './validators'

export default function PhoneNumberInput({ phoneNumber, setPhoneFunction, index, styles, disabled = false }) {
    const [phone, setPhoneNumber] = useState(phoneNumber);
    const [phoneEvent, setPhoneEvent] = useState('0');
    const [cursor, setCursor] = useState(0);
    const phoneRef = useRef();
    useEffect(() => {
        setPhoneFunction(phone, index)
        phoneRef.current.setSelectionRange(cursor, cursor);
    }, [phoneEvent])
    return (
        <input
            className={styles}
            type="text"
            disabled={disabled}
            ref={phoneRef}
            placeholder="Укажите Ваш номер телефона"
            value={phone}
            onChange={event => {
                let phoneMaskInfo = phoneMask(event);
                setPhoneNumber(phoneMaskInfo.value)
                setCursor(phoneMaskInfo.position);
                setPhoneEvent(event.timeStamp)
            }}
        />
    )
}