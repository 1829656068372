
import AppHeader from '../header'
import AppFooter from '../footer'

export default function Layout({ children }) {
    return (
        <>
            <AppHeader />
            {children}
            <AppFooter />
        </>
    )
}