import React, { useContext } from "react";
import styles from '../section.module.css'
import { validateCity, validateSpecialties } from '../validators'
import Dropdown from '../dropdown'
import AcceptFileInput from '../fileInput'
import { UserContext, reducerActions } from "../../../global/context"
import { possibleCities } from "../../../global/constants"
import basicStyles from "../../../styles/basic.module.css"
import sendMetricsEvent from "../../../global/analytics"

export default function RegistrationSecondPage({ constants }) {
    const registrationFormInfo = useContext(UserContext);
    const { state, validationObject, validate, dispatch, functions } = registrationFormInfo;
    const { changeArray, addToArray } = functions;
    const { city, specialty, diploma } = state;
    const { possibleSpecialties } = constants;
    const setCity = (city) => {
        dispatch({ type: reducerActions.UPDATE, payload: { city } });
    }
    const setDiploma = (diploma) => {
        dispatch({ type: reducerActions.UPDATE, payload: { diploma } });
    }
    const setOneSpecialty = (chosenSpecialty, index) => {
        changeArray(chosenSpecialty, index, specialty, "specialty", dispatch)
    }
    const addSpecialty = () => {
        addToArray(specialty, dispatch, "specialty")
    }
    const handler = () => {
        const capitalizedCity = city.replace(/( |^)[а-яёa-z]/g, l => l.toUpperCase());
        const isCityValid = validateCity(capitalizedCity);
        const isSpecialtyValid = validateSpecialties(specialty);
        validate(prev => ({ ...prev, city: isCityValid, specialty: isSpecialtyValid }))
        if (isCityValid && isSpecialtyValid) {
            sendMetricsEvent('registration', 'registrationStepTwo')
            dispatch({
                type: reducerActions.STEP_FORWARD,
                payload: {
                    city: capitalizedCity,
                    specialty: specialty,
                    diploma
                }
            })
        }
    }
    const stepBack = () => {
        dispatch({ type: reducerActions.STEP_BACK })
    }
    const checkField = (field) => {
        if (field) return `${styles.dropdownBorder}`
        else return `${styles.errorBorder}`
    }
    return (
        <>
            <div className={styles.progress}><div className={styles.counter}>2/3</div><div className={styles.progressText}>Чтобы не перепутать с тёзкой</div></div>
            <div className={styles.inputTab} >
                <Dropdown
                    arrayKey="city"
                    element={city}
                    setElement={setCity}
                    possibleArray={possibleCities}
                    label="Город"
                    mandatory={true}
                    placeholder="Введите город"
                    styleObject={{
                        borderStyle: checkField(validationObject.city),
                        dropdownInputWrapper: styles.dropdownInputWrapper,
                        dropdownInput: styles.doctorInput + " " + styles.dropdownInput,
                        dropdownList: styles.dropdownList,
                        listElement: styles.listElement,
                        dropdownArrowUp: styles.dropdownArrowUp + " " + styles.dropdownArrow,
                        dropdownArrowDown: styles.dropdownArrowDown + " " + styles.dropdownArrow
                    }}
                />
                {specialty.map((e, i) => {
                    if (i > 0) {
                        return (
                            <Dropdown
                                key={'array' + i}
                                element={specialty[i]}
                                setElement={setOneSpecialty}
                                arrayIndex={i}
                                elementArray={specialty}
                                possibleArray={possibleSpecialties}
                                label={"Дополнительная специальность"}
                                mandatory={false}
                                arrayKey="specialty"
                                styleObject={{
                                    borderStyle: styles.dropdownBorder,
                                    dropdownInputWrapper: styles.dropdownInputWrapper,
                                    dropdownInput: styles.doctorInput + " " + styles.dropdownInput,
                                    dropdownList: styles.dropdownList,
                                    listElement: styles.listElement,
                                    dropdownArrowUp: styles.dropdownArrowUp + " " + styles.dropdownArrow,
                                    dropdownArrowDown: styles.dropdownArrowDown + " " + styles.dropdownArrow
                                }}
                            />
                        )
                    }
                    return (
                        <Dropdown
                            key={'array' + i}
                            element={specialty[i]}
                            setElement={setOneSpecialty}
                            arrayIndex={i}
                            elementArray={specialty}
                            possibleArray={possibleSpecialties}
                            label={"Cпециальность"}
                            mandatory={true}
                            arrayKey="specialty"
                            styleObject={{
                                borderStyle: checkField(validationObject.specialty) + "",
                                dropdownInputWrapper: styles.dropdownInputWrapper + " " + styles.dropdownWrapperSpecialty,
                                dropdownInput: styles.doctorInput + " " + styles.dropdownInput,
                                dropdownList: styles.dropdownList + " " + styles.dropdownListSpecialty,
                                listElement: styles.listElement,
                                dropdownArrowUp: styles.dropdownArrowUp + " " + styles.dropdownArrow,
                                dropdownArrowDown: styles.dropdownArrowDown + " " + styles.dropdownArrow
                            }}
                        />
                    )
                })}
                {(specialty.length < 5) ? (<div className={basicStyles.textButton}>{"+"}<span className={basicStyles.textLink} onClick={() => addSpecialty()}>{'Добавить специальность'}</span></div>) : (<></>)}
                <div className={styles.dropdownInputWrapper}>
                    <AcceptFileInput
                        format='application/pdf'
                        label='Ваш диплом'
                        text="Нажмите на данное поле, либо перетащите в него файл, чтобы загрузить диплом. Загружайте Ваш диплом в формате PDF."
                        setFile={setDiploma}
                        inputId='reg-upload'
                        styleObject={{
                            wrapper: "",
                            heading: styles.fillerText,
                            content: styles.cursive,
                            dropzone: `${styles.roundBorderInput} ${styles.cursive}`
                        }} />
                    {(diploma) ? (
                        <>
                            <div className={styles.fillerText} >Загружен файл:</div>
                            <div className={styles.doctorInput} >{diploma.name}</div>
                        </>
                    ) : (<></>)}
                </div>
            </div>
            <div className={styles.buttonRow}>
                <div className={`${basicStyles.basicButton} ${styles.buttonNext}`} onClick={() => handler()}><span>Далее</span></div>
            </div>
            <div className={basicStyles.textButton}>{"← "}<span className={basicStyles.textLink} onClick={() => stepBack()}>Назад</span></div>
        </>
    )
}
