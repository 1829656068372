import React from "react";
import styles from '../user.module.css'
import Popup from '../../popup'
export default function SaveSuccessPopup({
    updated,
    setUpdate,
    saveSuccess
}) {
    return (
        <Popup setPopup={setUpdate} status={updated} >
            <div className={styles.popupBox}>
                <div className={(saveSuccess) ? `${styles.popupHead} ${styles.saveSuccess} ${styles.userInfoLabel}` : `${styles.popupHead} ${styles.saveError} ${styles.userInfoLabel}`}>{(saveSuccess) ? "Данные успешно сохранены!" : "Произошла ошибка! Не удалось сохранить данные."}</div>
            </div>
        </Popup>
    )
}