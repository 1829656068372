import styles from '../user.module.css'
import basicStyles from '../../../styles/basic.module.css'
import { sources } from "../../../global/constants"
import sendMetricsEvent from "../../../global/analytics"
import moment from 'moment';

export default function DoctorReviewPanel({ currentReviews }) {
    // const [source, changeSourse] = useState("")
    // const date = new Date()
    // const lastMonthDate = date.setMonth(date.getMonth - 1)
    // const [selectionRange, setDate] = useState({
    //     startDate: new Date(new Date() - 30 * 24 * 60 * 60 * 1000),
    //     endDate: new Date(),
    //     key: 'selection',
    // });
    // const handleSelect = (ranges) => {
    //     setDate(ranges.selection);
    // }
    // const filterReviews = (reviews) => {
    //     const newReviews = reviews.filter(review =>
    //         review.date > selectionRange.startDate.toISOString() && review.date < selectionRange.endDate.toISOString()
    //     )
    //     return newReviews;
    // }
    const handleLinkClick = () => {
        sendMetricsEvent('review', 'reviewClick')

    }
    return (
        <>
            <div className={styles.userFullName}>
                Полученные отзывы
            </div>
            <div className={styles.userDetailsReviews}>
                <div className={styles.userDetailsDimplomaPanel}>
                    <div className={styles.userDetailsParametersPanel}>
                        <div className={styles.userDetailsReviewParameterSource}>
                            Источник
                        </div>
                        <div className={styles.userDetailsReviewParameterDate}>
                            Дата
                        </div>
                        <div className={styles.userDetailsReviewParameterLink}>
                            Ссылка
                        </div>
                    </div>
                    {/* {(filterReviews(currentReviews).length > 0) ? ( */}
                    {(currentReviews.length > 0) ? (
                        // filterReviews(currentReviews).map((review, index) => {
                        currentReviews.map((review, index) => {

                            let radiusClass = styles.userDetailsValuesPanel
                            if (index !== currentReviews.length - 1) {
                                radiusClass = radiusClass + " " + styles.userDetailsValuesMidPanel
                            }
                            return (
                                <div className={radiusClass} key={"review" + index}>
                                    <div className={styles.userDetailsReviewParameterSource}>
                                        <img
                                            src={sources[review.site_name].picture}
                                            height={20}
                                            width={20}
                                            alt='Сайт'
                                        />
                                        <a href={sources[review.site_name].link}
                                            style={{ marginLeft: '2%' }}
                                            className={basicStyles.textLink}>
                                            {sources[review.site_name].name}
                                        </a>
                                    </div>
                                    <div className={styles.userDetailsReviewParameterDate}>
                                        {moment(review.review_date).format("YYYY-MM-DD HH:mm")}
                                    </div>
                                    <div className={styles.userDetailsReviewParameterLink}>
                                        <a href={review.url} onClick={handleLinkClick} className={basicStyles.textLink}>
                                            Ссылка
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <div className={styles.userDetailsValuesPanel}>
                            <div className={styles.userDetailsReviewParameterSource}>
                                Ничего не найдено
                            </div>
                        </div>
                    )}

                </div>

                {/* <div className={styles.userDetailsFilter}>
                                        <div className={styles.userDetailsFilterDatePicker}>
                                            Фильтровать по дате
                                        <div className={styles.userDetailsFilterDates}>
                                                <DateRange
                                                    locale={ru}
                                                    className={styles.userDetailsFilterDatePickerWrapper}
                                                    // showDateDisplay={false}
                                                    editableDateInputs={true}
                                                    ranges={[selectionRange]}
                                                    onChange={handleSelect}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
            </div>
        </>
    )
}