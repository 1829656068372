import styles from '../user.module.css'

export default function NonEditableInfo({ state }) {
    return (
        <>
            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Фамилия</div>
                <div className={styles.userInfoContentWrapper}>
                    <div className={styles.userInfoContent}>{state.last_name}</div>
                    {(state.maidenName) ? (<div className={styles.userInfoContent}>{state.maiden_name}</div>) : (<></>)}
                </div>
            </div>
            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Имя</div>
                <div className={styles.userInfoContentWrapper}>
                    <div className={styles.userInfoContent}>{state.first_name}</div>
                </div>
            </div>
            {(state.middle_name.length === 0) ? (<></>) : (<div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Отчество</div>
                <div className={styles.userInfoContentWrapper}>
                    <div className={styles.userInfoContent}>{state.middle_name}</div>
                </div>
            </div>)}
            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Город</div>
                <div className={styles.userInfoContentWrapper}>
                    <div className={styles.userInfoContent}>{state.city}</div>
                </div>
            </div>
            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Специальность</div>
                <div className={styles.userInfoContentWrapper}>
                    {state.specialty.map((specialty, index) => (
                        < div key={"specialty" + index} className={styles.userInfoContent} > { specialty}</div>
                    ))}
                </div>
            </div>
            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Электронная почта</div>
                <div className={styles.userInfoContentWrapper}>
                    <div className={styles.userInfoContent}>{state.email}</div>
                    {(state.extra_email.length > 0) ? (<div className={styles.userInfoContent}>{state.extra_email}</div>) : (<></>)}

                </div>
            </div>

            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Телефон</div>
                <div className={styles.userInfoContentWrapper}>
                    {state.phone.map((phone, index) => (
                        < div key={"phone" + index} className={styles.userInfoContent} > { phone}</div>
                    ))}
                </div>
            </div>
            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Получать письма о новых отзывах</div>
                <div className={styles.userInfoContentWrapper}>
                    <input type="checkbox" checked={state.mail_send} className={styles.checkbox}
                        disabled
                        name="mailSendCheckboxInactive" />
                </div>
            </div>
        </>
    )
}