import React, { useEffect, useState } from "react";

export default function AcceptFileInput({ format, label, text, setFile, inputId, styleObject }) {
    const [acceptedFiles, setAcceptedFiles] = useState([])
    const [fileRejections, setFileRejections] = useState([])

    useEffect(() => {
        const updateFiles = () => {
            if (acceptedFiles[0]) setFile(acceptedFiles[0]);
            if (fileRejections[0]) setFile("");
        }
        updateFiles();
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [acceptedFiles])

    // const acceptedFileItems = () => {
    //     return (
    //         <>
    //             {(acceptedFiles[0]) ? (<>
    //                 <div className={styleObject.heading}>Готов к загрузке:</div>
    //                 <div className={styleObject.content}>
    //                     {acceptedFiles[0].path}
    //                 </div></>) : (<></>)}

    //         </>
    //     )
    // }
    const fileRejectionItems = () => {
        return (
            <>
                {(fileRejections[0]) ? (<>
                    <div className={styleObject.heading}>Не удается загрузить:</div>
                    <div className={styleObject.content}>
                        {fileRejections[0].name}
                    </div></>) : (<></>)}

            </>
        )
    }
    const dropzoneClick = () => {
        const dropzoneInput = document.getElementById(inputId)
        dropzoneInput.click()
    }
    const prevent = (event) => {
        event.stopPropagation();
        event.preventDefault();
    }

    const handleChange = (event) => {
        prevent(event)
        handleFiles(event.target.files)
    }
    const handleDrop = (event) => {
        prevent(event)
        let dt = event.dataTransfer
        let files = dt.files
        handleFiles(files)
    }
    const handleFiles = (loadedFiles) => {
        const loadedObjects = [...loadedFiles]
        let accept = new DataTransfer()
        let decline = new DataTransfer()
        for (let index in loadedObjects) {
            const file = loadedFiles[index]
            if (loadedObjects[index].type === format) accept.items.add(file)
            else decline.items.add(file)
        }
        setAcceptedFiles(accept.files)
        setFileRejections(decline.files)
    }
    return (
        <div>
            <div
                tabIndex="0"
                className={styleObject.wrapper}>
                <input
                    accept={format}
                    multiple
                    type="file"
                    autoComplete="off"
                    tabIndex="-1"
                    style={{ display: 'none' }}
                    id={inputId}
                    onChange={handleChange} />
                {(label) ? (<div className={styleObject.heading}>{label}</div>) : (<></>)}
                <div
                    className={styleObject.dropzone}
                    onClick={() => dropzoneClick()}
                    onDrop={handleDrop}
                    onDragOver={(event) => {
                        prevent(event)
                    }}
                    onDragEnter={(event) => {
                        prevent(event)
                    }}>

                    <div className={styleObject.content}> {text}</div>
                    {/* <div>{acceptedFileItems()}</div> */}
                    <div>{fileRejectionItems()}</div>
                </div>

            </div>
        </div>
    );
}