
import styles from '../user.module.css'
import { possibleCities } from '../../../global/constants'
// import { capitalize } from "../../../global/utils"
import Dropdown from '../../registrationSection/dropdown'
import { UserContext, reducerActions } from "../../../global/context"
// import basicStyles from '../../../styles/basic.module.css'
import PhoneNumberInput from '../../registrationSection/phoneInput'
// import { validateEmails, validatePhones, validateNames, validateCity, validateSpecialties } from '../../registrationSection/validators'
import React, { useState, useContext, useEffect } from "react";
import { getUpdatedSpecialtiesList } from '../../../global/api'
import sendRequest from '../../../global/api'

export default function EditableInfo({ isEditingOn,
    currentUser,
    setSaveSuccess,
    setUpdate
}) {
    const UserFormInfo = useContext(UserContext);
    const [constants, setConstants] = useState({})
    // const { state, dispatch, validationObject, validate, functions } = UserFormInfo;
    const { state, dispatch, validationObject, functions } = UserFormInfo;
    const { first_name, last_name, middle_name, maiden_name, specialty, city, phone, email, extra_email } = state;
    // const [extraEmailOn, setExtraEmailOn] = useState(extra_email.length);
    const [extraEmailOn] = useState(extra_email.length);
    // const { addToValidator, deleteFromValidator, changeArray, addToArray, deleteFromArray } = functions;
    // const { changeArray, addToArray, deleteFromArray } = functions;
    const { changeArray } = functions;

    const setCity = (city) => {
        dispatch({ type: reducerActions.UPDATE, payload: { city } });
    }
    const changePhones = (value, index) => {
        changeArray(value, index, phone, 'phone', dispatch)
    }
    // const addPhone = () => {
    //     addToArray(phone, dispatch, "phone");
    // }
    // const deletePhone = () => {
    //     deleteFromArray(phone, dispatch, "phone");
    // }
    const setOneSpecialty = (chosenSpecialty, index) => {
        changeArray(chosenSpecialty, index, specialty, "specialty", dispatch)
    }
    // const addSpecialty = () => {
    //     addToArray(specialty, dispatch, "specialty")
    // }
    const handleChange = (event) => {
        dispatch({ type: "UPDATE", payload: { mail_send: event.target.checked } });
    };
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const isEmailValid = validateEmails(email);
    //     const isExtraEmailValid = (extraEmailOn && extra_email.length) ? validateEmails(extra_email) : true;
    //     const cleanPhoneNumbers = phone.map(p => p.replace(/\D/g, ''))
    //     const validatedPhones = validatePhones(cleanPhoneNumbers);
    //     const isPhoneValid = !validatedPhones.some(e => e === false)
    //     const capitalizedCity = city.replace(/( |^)[а-яёa-z]/g, l => l.toUpperCase());
    //     const isCityValid = validateCity(capitalizedCity);
    //     const isSpecialtyValid = validateSpecialties(specialty);
    //     const isFirstNameValid = validateNames(first_name);
    //     const isLastNameValid = validateNames(last_name);
    //     validate(prev => ({
    //         ...prev,
    //         email: isEmailValid,
    //         extra_email: isExtraEmailValid,
    //         phone: validatedPhones,
    //         city: isCityValid,
    //         specialty: isSpecialtyValid,
    //         first_name: isFirstNameValid,
    //         last_name: isLastNameValid
    //     }))
    //     if (isFirstNameValid && isExtraEmailValid && isLastNameValid && isCityValid && isSpecialtyValid && isEmailValid && isPhoneValid) {
    //         dispatch({
    //             type: "UPDATE", payload: {
    //                 first_name: capitalize(first_name),
    //                 last_name: capitalize(last_name),
    //                 maiden_name: capitalize(maiden_name),
    //                 middle_name: capitalize(middle_name),
    //                 city: capitalizedCity,
    //                 specialty: specialty,
    //                 email,
    //                 phone
    //             }
    //         })
    //         isEditingOn(false)
    //     }
    // }
    const handleSubmit = (event) => {
        event.preventDefault();
        setMails();

    }
    const checkField = (field) => {
        let string = `${styles.userInfoContent}`
        if (!field) string += ` ${styles.errorBorder}`
        return string
    }
    useEffect(async () => {
        let possibleSpecialties = await getUpdatedSpecialtiesList()
        setConstants({
            possibleSpecialties: possibleSpecialties,
        })
    }, []);

    const setMails = async () => {
        setUpdate(false)
        const updateResponse = await sendRequest("UPDATE_DOCTOR", { mail_send: (state.mail_send) ? 'True' : 'False' }, state.token)
        if (updateResponse.status && updateResponse.status === 200) {
            const doctorResponse = await sendRequest("GET_DOCTOR", {}, state.token)
            const { mail_send } = doctorResponse.data.results[currentUser]
            dispatch({
                type: "UPDATE",
                payload: {
                    mail_send
                }
            })
            setSaveSuccess(true)
        }
        else {
            setSaveSuccess(false)
        }
        setUpdate(true)
        isEditingOn(false)
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Фамилия</div>
                <div className={styles.userInfoContentWrapper}>
                    <input className={checkField(validationObject.last_name)}
                        type="text"
                        disabled
                        value={last_name}
                        onChange={e => dispatch({
                            type: reducerActions.UPDATE,
                            payload: { last_name: e.target.value }
                        })}
                        placeholder="Введите Вашу фамилию" />
                    {maiden_name.length ? (<input className={styles.userInfoContent}
                        type="text"
                        disabled
                        value={maiden_name}
                        onChange={e => dispatch({
                            type: reducerActions.UPDATE,
                            payload: { maiden_name: e.target.value }
                        })}
                        placeholder="Введите Вашу девичью фамилию" />) : (<></>)}
                </div>
            </div>
            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Имя</div>
                <div className={styles.userInfoContentWrapper}>
                    <input className={checkField(validationObject.first_name)}
                        type="text"
                        disabled
                        value={first_name}
                        onChange={e => dispatch({
                            type: reducerActions.UPDATE,
                            payload: { first_name: e.target.value }
                        })}
                        placeholder="Введите Ваше имя" />
                </div>
            </div>
            {middle_name.length ? (<div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Отчество</div>
                <div className={styles.userInfoContentWrapper}>
                    <input className={styles.userInfoContent}
                        type="text"
                        disabled
                        value={middle_name}
                        onChange={e => dispatch({
                            type: reducerActions.UPDATE,
                            payload: { middle_name: e.target.value }
                        })}
                        placeholder="Введите Ваше отчество" />
                </div>
            </div>) : (<></>)}
            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Город</div>
                <div className={styles.userInfoContentWrapper}>
                    <Dropdown
                        arrayKey="city"
                        element={city}
                        setElement={setCity}
                        possibleArray={possibleCities}
                        mandatory={true}
                        disabled
                        placeholder="Введите город"
                        styleObject={{
                            borderStyle: "",
                            dropdownInputWrapper: styles.dropdownInputWrapper,
                            dropdownInput: checkField(validationObject.city),
                            dropdownList: styles.dropdownList,
                            listElement: styles.listElement,
                            dropdownArrowUp: styles.dropdownArrowUp,
                            dropdownArrowDown: styles.dropdownArrowDown
                        }} />
                </div>
            </div>
            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Специальность</div>
                <div className={styles.userInfoContentWrapper}>
                    {specialty.map((e, i) => {
                        if (i > 0) {
                            return (
                                <Dropdown
                                    key={'array' + i}
                                    element={e}
                                    setElement={setOneSpecialty}
                                    arrayIndex={i}
                                    elementArray={specialty}
                                    possibleArray={constants.possibleSpecialties}
                                    mandatory={false}
                                    arrayKey="specialty"
                                    disabled
                                    styleObject={{
                                        borderStyle: "",
                                        dropdownInputWrapper: styles.dropdownInputWrapper,
                                        dropdownInput: styles.userInfoContent,
                                        dropdownList: styles.dropdownList,
                                        listElement: styles.listElement,
                                        dropdownArrowUp: styles.dropdownArrowUp,
                                        dropdownArrowDown: styles.dropdownArrowDown
                                    }}
                                />
                            )
                        }
                        return (
                            <Dropdown
                                key={'array' + i}
                                element={e}
                                setElement={setOneSpecialty}
                                arrayIndex={i}
                                elementArray={specialty}
                                possibleArray={constants.possibleSpecialties}
                                mandatory={true}
                                arrayKey="specialty"
                                disabled
                                styleObject={{
                                    borderStyle: "",
                                    dropdownInputWrapper: styles.dropdownInputWrapper,
                                    dropdownInput: checkField(validationObject.specialty),
                                    dropdownList: styles.dropdownList,
                                    listElement: styles.listElement,
                                    dropdownArrowUp: styles.dropdownArrowUp,
                                    dropdownArrowDown: styles.dropdownArrowDown
                                }}
                            />
                        )
                    })}
                    {/* {(specialty.length < 5) ? (<div className={styles.backButton}>{"+"}<span className={styles.backButtonText} onClick={() => addSpecialty()}>{'Добавить специальность'}</span></div>) : (<></>)} */}
                </div>
            </div>
            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Электронная почта</div>
                <div className={styles.userInfoContentWrapper}>
                    <input
                        className={checkField(validationObject.email)}
                        type="text"
                        value={email}
                        disabled
                        placeholder="Укажите Ваш Email"
                        onChange={e => dispatch({
                            type: reducerActions.UPDATE,
                            payload: { email: e.target.value }
                        })}
                    />
                    {/* {(!extraEmailOn) ? (
                        <div className={styles.buttonRow}>
                            <div className={basicStyles.textButton}>{"+"}<span className={basicStyles.textLink} onClick={() => {
                                setExtraEmailOn(true)
                            }}>{'Добавить'}</span></div>
                        </div>
                    ) : (<></>)} */}
                    {(extraEmailOn || extra_email.length) ? (
                        <input
                            className={checkField(validationObject.extra_email)}
                            type="text"
                            disabled
                            placeholder="Укажите Ваш дополнительный Email"
                            value={extra_email}
                            onChange={e => dispatch({
                                type: reducerActions.UPDATE,
                                payload: { extra_email: e.target.value }
                            })}
                        />
                    ) : (<></>)}

                </div>
            </div>
            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Телефон</div>
                <div className={styles.userInfoContentWrapper}>
                    {phone.map((element, index) => {
                        return (
                            <div key={"phone" + index} className={styles.inputSection}>
                                <PhoneNumberInput
                                    styles={checkField(validationObject.phone[index])}
                                    phoneNumber={element}
                                    setPhoneFunction={changePhones}
                                    index={index}
                                    disabled
                                />
                                {/* {(index === phone.length - 1) ? (
                                    <div className={styles.buttonRow}>
                                        <div className={basicStyles.textButton}>{"+"}<span className={basicStyles.textLink} onClick={() => {
                                            addPhone();
                                            addToValidator('phone')
                                        }}>{'Добавить'}</span></div>
                                        {(index !== 0) ? (<div className={basicStyles.textButton}>{"-"}<span className={basicStyles.textLink} onClick={() => {
                                            deletePhone();
                                            deleteFromValidator('phone')
                                        }}>{'Удалить'}</span></div>) : (<></>)}
                                    </div>
                                ) : (<></>)} */}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={styles.userInfoRow}>
                <div className={styles.userInfoLabel}>Получать письма о новых отзывах</div>
                <div className={styles.userInfoContentWrapper}>
                    <input type="checkbox" checked={state.mail_send} className={styles.checkbox}
                        onChange={handleChange}
                        name="mailSendCheckbox" />
                </div>
            </div>

            <button type="submit" className={styles.changeButton + " " + styles.saveButton}>
                Сохранить
                </button>
        </form>
    )
}