import styles from './section.module.css'
import basicStyles from '../../styles/basic.module.css'
import ScrollLink from '../scrollLink';

export default function SecondSection() {
    return (
        <div id="about" className={styles.container}>
            <img src="/images/second_screen_mobile.png" className={styles.secondBackImage} alt={''} />
            <div className={styles.textContainer}>
                <div className={styles.heading}>
                    DoctorOnBoard
                </div>
                <div className={styles.text}>
                    С помощью сервиса DoctorOnBoard Вы вовремя узнаете о негативных постах в соцмедиа и СМИ, а также оперативно поблагодарите ваших пациентов за положительные отзывы.
                </div>
                <ScrollLink scrollTo="header" className={`${basicStyles.basicButton} ${styles.scanButton}`}>
                    <div> Сканировать</div>
                </ScrollLink>
            </div>
        </div>
    )
}