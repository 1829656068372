import React, { useState, useEffect, useContext } from "react";
// import { DateRange } from 'react-date-range';
// import { ru } from 'date-fns/locale'
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; 
import EditableInfo from './infoPanelEditable'
import NonEditableInfo from './infoPanelNonEditable'
import DiplomaPanel from './diplomaPanel'
import DoctorReviewPanel from './reviewPanel'
import SaveSuccessPopup from './saveSuccessPopup'
import styles from './user.module.css'
// import { possibleCities, possibleSpecialties } from '../global/constants'
// import { Dropdown } from '../components/registrationSection/registrationSecondPage'
// import { PhoneNumberInput } from '../components/registrationSection/registrationThirdPage'
// import { justFormatNumbersIn, validateEmails, validatePhones, validateNames, validateCity, validateSpecialties } from '../../components/registrationSection/validators'
// import { justFormatNumbersIn } from '../../components/registrationSection/validators'
import sendRequest from "../../global/api"
import { loadToken } from "../../global/token"
import { UserContext } from "../../global/context"

export default function User() {
    const UserFormInfo = useContext(UserContext);
    const { state, dispatch } = UserFormInfo;
    const [loading, setLoading] = useState(true)
    const [allDoctors, setAllDoctors] = useState([])
    const [saveSuccess, setSaveSuccess] = useState(false)
    const [updated, setUpdate] = useState(false)
    const [forbidden, setForbidden] = useState(false)
    const [editing, isEditingOn] = useState(false)
    const [currentUser, setCurrentUser] = useState(0)
    const [currentReviews, setCurrentReviews] = useState([])
    useEffect(async () => {
        setLoading(true)
        const token = loadToken();
        if (token) {
            const doctorResponse = await sendRequest("GET_DOCTOR", {}, token)
            setCurrentUser(0)
            if (doctorResponse.status === 200) {
                const formatedDoctors = formatAllDoctors(doctorResponse.data.results)
                setAllDoctors(formatedDoctors)
                setCurrentReviews(formatedDoctors[currentUser].reviews)
            }
            else {
                setForbidden(true)
            }
            setLoading(false)
        }
        else {
            setForbidden(true)
            setLoading(false)
        }
    }, []);
    const formatAllDoctors = (results) => {
        const newResults = results.map(doctor => {
            return {
                id: doctor.id,
                first_name: doctor.first_name,
                last_name: doctor.last_name,
                maiden_name: (doctor.maiden_name) ? doctor.maiden_name : "",
                middle_name: (doctor.middle_name) ? doctor.middle_name : "",
                email: doctor.email,
                extra_email: (doctor.extra_email) ? doctor.extra_email : "",
                phone: doctor.phone.map(p => p.phone),
                specialty: doctor.specialty.map(s => s.specialty[0].toUpperCase() + s.specialty.slice(1)),
                city: doctor.city,
                diploma: (doctor.diploma) ? decodeURI(doctor.diploma) : "",
                verified: doctor.verified,
                mail_send: doctor.mail_send,
                reviews: doctor.reviews,
            }
        })
        dispatch({
            type: "UPDATE", payload: { ...newResults[currentUser], token: loadToken() }
        })
        return newResults
    }

    const updateCurrentDoctor = (currentUser) => {
        dispatch({
            type: "UPDATE", payload: allDoctors[currentUser]
        })
        setCurrentReviews(allDoctors[currentUser].reviews)
    }
    return (
        <>{(forbidden) ? (
            <div className={styles.container}>
                <div className={styles.loadingPanel}>
                    <div> Страница недоступна. Осуществите вход.</div>
                </div>
            </div>) : (
            <>
                {(loading) ? (
                    <div className={styles.container}>
                        <div className={styles.loadingPanel}>
                            <div> Загружаем страницу...</div>
                        </div>
                    </div>
                ) : (
                    <div className={styles.container}>
                        {(allDoctors.length > 1) ? (
                            <div className={styles.userChangeRow}>
                                {allDoctors.map(doctor => doctor.last_name + " " + doctor.first_name[0] + '.').map((name, index) => (
                                    <div key={"name" + index}
                                        style={{ backgroundColor: (currentUser === index) ? '#e5f7f9' : 'white' }}
                                        className={styles.nameButton}
                                        onClick={() => {
                                            setCurrentUser(index)
                                            updateCurrentDoctor(index)
                                        }}
                                    >
                                        {name}
                                    </div>
                                ))}
                            </div>
                        ) : (<></>)}
                        <div className={styles.userPagePanel}>
                            <div className={styles.userPersonalInfo}>
                                <div className={styles.userBasicPersonalInfoRow}>
                                    <div className={styles.userPersonalInfoNameAndAvatar}>
                                        <div className={styles.userPersonalInfoAvatar}>
                                            {state.first_name[0] + state.last_name[0]}
                                        </div>
                                        <div className={styles.userPersonalInfoName}>Личные данные</div>
                                    </div>
                                    {(!editing) ? (<div className={styles.userPersonalInfoChangeButton} onClick={() => isEditingOn(true)}>
                                        <div className={styles.changeButton} > Изменить</div>
                                    </div>) : (<></>)}
                                </div>

                                <div className={styles.userFullName}>
                                    {state.last_name + " " + state.first_name + " " + state.middle_name}
                                </div>

                                <div className={styles.userDetailedPersonalInfo}>

                                    {(editing) ? (<EditableInfo
                                        currentUser={currentUser}
                                        setSaveSuccess={setSaveSuccess}
                                        setUpdate={setUpdate}
                                        isEditingOn={isEditingOn} />) : (<NonEditableInfo state={state} />)}
                                </div>
                                <DiplomaPanel
                                    currentUser={currentUser}
                                    setSaveSuccess={setSaveSuccess}
                                    setUpdate={setUpdate}
                                />
                                <DoctorReviewPanel
                                    currentReviews={currentReviews} />
                                <SaveSuccessPopup
                                    updated={updated}
                                    setUpdate={setUpdate}
                                    saveSuccess={saveSuccess}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </>)}
        </>
    )
}

