import React from "react";
import styles from './popup.module.css'

export default function Popup({ status, setPopup, children }) {
    return (
        <div className={(status) ? (`${styles.popupBackground} ${styles.popupActive}`) : (`${styles.popupBackground}`)}
            onClick={(e) => {
                setPopup(false)
            }}>
            {children}
        </div>
    )
}