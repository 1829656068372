import axios from 'axios';
import { API_ROOT } from '../api'
import { possibleSpecialtiesConstant } from './constants'
import { capitalize } from './utils'
const BASE = (API_ROOT) ? API_ROOT : ""
function toFormData(object) {
    const formData = new FormData()
    for (let key in object) {
        if (Array.isArray(object[key])) {

            const filtered = object[key].filter(o => o.length > 0)
            for (let index in filtered) {
                formData.append(key, filtered[index]);
            }
        }
        else formData.append(key, object[key]);
    }
    return formData
}
export default async function sendRequest(requestType, requestContent = {}, token = "", id = "") {
    switch (requestType) {
        case "GET_SPECIALTY": {
            const res = await axios.get(`${BASE}/doctors/specialty/`).catch((error) => {
                return {
                    data: {
                        results: []
                    }
                }
            });
            return res.data.results
        }
        case "REGISTER": {
            const formData = toFormData(requestContent);
            const res = await axios.post(`${BASE}/auth/registration/`, formData).catch((error) => error.response);
            return res;
        }
        case "LOGIN": {
            const formData = toFormData(requestContent);
            const res = await axios.post(`${BASE}/auth/login/`, formData).catch((error) => error.response);
            return res;
        }
        case "LOGOUT": {
            const res = await axios.post(`${BASE}/auth/logout/`, { headers: { "Authorization": "Token " + token } }).catch((error) => error.response);
            return res;
        }
        case "GET_DOCTOR": {
            const res = await axios.get(`${BASE}/doctors/doctor/`, { headers: { "Authorization": "Token " + token } }).catch((error) => error.response);
            return res;
        }
        case "GET_REVIEWS": {
            const res = await axios.get(`${BASE}/reviews/review/`, { headers: { "Authorization": "Token " + token } }).catch((error) => error.response);
            return res;
        }
        case "GET_ONE_SPECIALTY": {
            const res = await axios.get(`${BASE}/doctors/specialty/${id}/`, { headers: { "Authorization": "Token " + token } }).catch((error) => error.response);
            return res;
        }
        case "UPDATE_DOCTOR": {
            const formData = toFormData(requestContent);
            const res = await axios.put(`${BASE}/doctors/doctor/update/`, formData, { headers: { "Authorization": "Token " + token } }).catch((error) => error.response);
            return res;
        }
        default: {
            return {}
        }
    }
}
export const getUpdatedSpecialtiesList = async () => {
    let responseSpecialties = await sendRequest("GET_SPECIALTY");
    if (responseSpecialties.length) {
        for (let index in responseSpecialties) {
            if (!possibleSpecialtiesConstant.some(obj => obj.specialty.toLowerCase() === responseSpecialties[index].specialty.toLowerCase())) {
                responseSpecialties[index].specialty = capitalize(responseSpecialties[index].specialty)
                possibleSpecialtiesConstant.push(responseSpecialties[index])
            }
        }
    }
    return possibleSpecialtiesConstant
}