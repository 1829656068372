const ScrollLink = ({
    children,
    scrollTo = null,
    className = ''
}) => {
    return (
        <div
            onClick={() => {
                const element = document.getElementById(scrollTo)
                element.scrollIntoView({ behavior: "smooth", block: "start" })
            }}
            className={className}>
            {children}
        </div>
    )
}

export default ScrollLink