import './App.css';
import Layout from '../src/components/layout'
import Home from '../src/components/homePage'
import User from '../src/components/user'
import { UserContextProvider } from '../src/global/context'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import React, { useEffect } from "react";

ReactGA.initialize('UA-144893926-2');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const sendOneMinuteEvent = () => {
    console.log('send')
    ym('reachGoal', 'oneMinuteOnPage');
  }
  useEffect(() => {
    setTimeout(sendOneMinuteEvent, 60 * 1000)
  }, [])
  return (
    <YMInitializer accounts={[82197037]} options={{ webvisor: true, clickmap: true, trackLinks: true, accurateTrackBounce: true }}>
      <Router>
        <UserContextProvider>
          <Layout>
            <Switch>
              <Route path="/user">
                <User />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Layout>
        </UserContextProvider>
      </Router>
    </YMInitializer>
  )
}

export default App;
