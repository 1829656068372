import React from "react";
import styles from './section.module.css'
import Popup from '../popup'
export default function RegistrationPopup({
    status,
    setPopup,
    popupContent
}) {
    return (
        <Popup setPopup={setPopup} status={status} >
            <div className={styles.popupBox}>
                <div className={`${styles.popupHead} ${styles.blueText} ${styles.heading}`} >{popupContent.heading}</div>
                <div className={`${styles.fillerText} ${styles.popupText}`}>{popupContent.text}</div>
            </div>
        </Popup>
    )
}