import React, { useState, useContext } from "react";
import styles from '../section.module.css'
import { validateEmails, validatePhones } from '../validators'
import PhoneNumberInput from '../phoneInput'
import sendRequest from '../../../global/api'
import { UserContext, reducerActions } from "../../../global/context"
import basicStyles from "../../../styles/basic.module.css"
import sendMetricsEvent from "../../../global/analytics"

export default function RegistrationThirdPage({ setPopup, setPopupContent }) {
    const registrationFormInfo = useContext(UserContext); const { state, validationObject, validate, dispatch, functions } = registrationFormInfo;
    const { specialty, phone, email, extra_email } = state;
    const { addToValidator, deleteFromValidator, changeArray, addToArray, deleteFromArray } = functions;
    const [extraEmailOn, setExtraEmailOn] = useState(false);
    const [loading, setLoading] = useState(false);
    const changePhones = (value, index) => {
        changeArray(value, index, phone, 'phone', dispatch)
    }
    const addPhone = () => {
        addToArray(phone, dispatch, "phone");
    }
    const deletePhone = () => {
        deleteFromArray(phone, dispatch, "phone");
    }
    const handler = async () => {
        const isEmailValid = validateEmails(email);
        const isExtraEmailValid = (extraEmailOn) ? validateEmails(extra_email) : true;
        const cleanPhoneNumbers = phone.map(p => p.replace(/\D/g, ''))
        const validatedPhones = validatePhones(cleanPhoneNumbers);
        const isPhoneValid = !validatedPhones.some(e => e === false)
        validate(prev => ({
            ...prev,
            email: isEmailValid,
            extra_email: isExtraEmailValid,
            phone: validatedPhones
        }))
        if (isEmailValid && isPhoneValid && isExtraEmailValid) {
            sendMetricsEvent('registration', 'registrationSendForm')
            dispatch({ type: reducerActions.UPDATE, payload: { email, extra_email, phone } })
            setLoading(true);
            const registerResponse = await sendRequest("REGISTER", {
                ...state,
                specialty: specialty.filter(s => s.length > 0).map(s => s.toLowerCase()),
                verified: "CONFIRMING"
            })
            if (registerResponse.status === 201) {
                setPopup(true)
                dispatch({ type: reducerActions.STEP_FORWARD })
                setPopupContent({ heading: "Спасибо", text: "Мы пришлем письмо о подтверждении на ваш email" })

            }
            else {
                if (registerResponse.status === 400 && registerResponse.data.email[0].length > 1) {
                    setLoading(false);
                    setPopupContent({ heading: "Ошибка", text: "Указанный вами адрес электронной почты уже занят" })
                    validate(prev => ({ ...prev, email: false }))
                    setPopup(true)
                }
                else {
                    setLoading(false);
                    setPopupContent({ heading: "Ошибка", text: "Что-то пошло не так..." })
                    setPopup(true)

                }
            }
        }
    }
    const checkField = (field) => {
        let string = `${styles.doctorInput}`
        if (!field) string += ` ${styles.errorBorder}`
        else string += ` ${styles.standardBorder}`
        return string
    }
    const stepBack = () => {
        dispatch({ type: reducerActions.STEP_BACK })
    }
    return (
        <>
            {(loading) ? (
                <div className={styles.inputTab}>
                    <div className={`${styles.sectionText} ${styles.fillerText}`}>Отправлям форму, подождите...</div>
                </div>) : (<>

                    <div className={styles.progress}><div className={styles.counter}>3/3</div><div className={styles.progressText}>Для оповещения о новых отзывах</div></div>
                    <div className={styles.inputTab}>
                        <div className={styles.inputSection}>
                            <div className={styles.fillerText}>
                                Email<span className={styles.star}>{' *'}</span>
                            </div>
                            <input
                                className={checkField(validationObject.email)}
                                type="text"
                                placeholder="Укажите Ваш Email"
                                value={email}
                                onChange={e => dispatch({
                                    type: reducerActions.UPDATE,
                                    payload: { email: e.target.value }
                                })}
                            />
                            {(!extraEmailOn) ? (
                                <div className={styles.buttonRow}>
                                    <div className={basicStyles.textButton}>{"+"}<span className={basicStyles.textLink} onClick={() => {
                                        setExtraEmailOn(true)
                                    }}>{'Добавить'}</span></div>
                                </div>
                            ) : (<></>)}
                        </div>
                        {(extraEmailOn) ? (
                            <div className={styles.inputSection}>
                                <div className={styles.fillerText}>Дополнительный Email</div>
                                <input
                                    className={checkField(validationObject.extra_email)}
                                    type="text"
                                    placeholder="Укажите Ваш Email"
                                    value={extra_email}
                                    onChange={e => dispatch({
                                        type: reducerActions.UPDATE,
                                        payload: { extra_email: e.target.value }
                                    })}
                                />
                                <div className={basicStyles.textButton}>{"-"}<span className={basicStyles.textLink} onClick={() => {
                                    setExtraEmailOn(false)
                                }}>{'Удалить'}</span></div>
                            </div>

                        ) : (<></>)}
                        {phone.map((element, index) => {
                            return (
                                <div key={"phone" + index} className={styles.inputSection}>
                                    {(index === 0) ? (<div className={styles.fillerText}>
                                        Телефон
                                        <span className={styles.star}>{' *'}</span>
                                    </div>) : (<div className={styles.fillerText}>
                                        Дополнительный Телефон</div>)}
                                    <PhoneNumberInput
                                        styles={checkField(validationObject.phone[index])}
                                        phoneNumber={element}
                                        setPhoneFunction={changePhones}
                                        index={index}
                                    />
                                    {(index === phone.length - 1) ? (
                                        <div className={styles.buttonRow}>
                                            <div className={basicStyles.textButton}>{"+"}<span className={basicStyles.textLink} onClick={() => {
                                                addPhone()
                                                addToValidator('phone')
                                            }}>{'Добавить'}</span></div>
                                            {(index !== 0) ? (<div className={basicStyles.textButton}>{"-"}<span className={basicStyles.textLink} onClick={() => {
                                                deletePhone()
                                                deleteFromValidator('phone')
                                            }}>{'Удалить'}</span></div>) : (<></>)}
                                        </div>
                                    ) : (<></>)}
                                </div>
                            )
                        })}
                    </div>
                    <div className={styles.buttonRow}>
                        <div className={`${basicStyles.basicButton} ${styles.buttonNext}`} onClick={() => handler()}><span>Начать пользоваться</span></div>
                    </div>
                    <div className={basicStyles.textButton}>{"← "}<span className={basicStyles.textLink} onClick={() => stepBack()}>Назад</span></div>
                    <div className={styles.termsOfUseText}>Нажимая на кнопку «Начать пользоваться», вы даете <a href={"/docs/Soglasie_na_obrabotku_PD_1.doc"} className={styles.docLink}>согласие</a> на обработку персональных данных и принимаете условия <a href={"/docs/Polzovatelskoe_soglashenie_MK.doc"} className={styles.docLink}>пользовательского соглашения</a> .</div>
                </>)}
        </>
    )
}