import React, { useState, useEffect, useContext } from "react";
import styles from './header.module.css'
import { Link } from 'react-router-dom';
import ScrollLink from '../scrollLink';
import { loadToken, saveToken, removeToken } from "../../global/token"
import sendRequest from '../../global/api'
import { HeaderLoginContext, UserContext, reducerActions, defaultUserInfo } from "../../global/context"
import basicStyles from "../../styles/basic.module.css"
import sendMetricsEvent from "../../global/analytics"
import { useHistory, useLocation } from 'react-router-dom';

function Links({ desktop }) {
    return (
        <div className={(desktop) ? `${styles.links} ${styles.desktopElement}` : `${styles.links} ${styles.mobileElement}`}>
            <ScrollLink scrollTo="about" className={styles.topnav}>
                Сервис
            </ScrollLink >
            <ScrollLink scrollTo="footer" className={styles.topnav}>
                Контакты
            </ScrollLink >
        </div>
    )
}
function Auth({ desktop }) {
    const history = useHistory();
    let location = useLocation();
    const headerLoginFormInfo = useContext(HeaderLoginContext);
    const UserFormInfo = useContext(UserContext);
    const { dispatch } = UserFormInfo;
    const { isLoggedIn, setLogIn, changeMobileBar, isLoading } = headerLoginFormInfo;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [badCredentials, setBadCredentials] = useState(false);
    let media = ` ${styles.desktopElement}`
    if (!desktop) media = ` ${styles.mobileElement}`
    useEffect(() => {
        changeMobileBar(false)
    }, [location])
    const handleSubmit = async (e) => {
        e.preventDefault();
        const loginResponse = await sendRequest("LOGIN", { email, password })
        if (loginResponse.status === 200) {
            sendMetricsEvent('login', 'login')
            saveToken(loginResponse.data.key)
            setLogIn(true)
            changeMobileBar(false)
            history.push('/user');
        }
        else {
            setBadCredentials(true)
        }
    }
    const logOut = async () => {
        const loginResponse = await sendRequest("LOGOUT")
        if (loginResponse.status === 200) {
            removeToken()
            setLogIn(false)
            changeMobileBar(false)
            dispatch({
                type: reducerActions.UPDATE, payload: defaultUserInfo
            })
            history.push('/');
        }
    }
    return (isLoading) ? (<></>) : (<>
        {(isLoggedIn) ? (
            <div className={`${styles.links}` + media}>
                <Link to={'/user'} className={styles.topnav}>
                    Личный кабинет
                </Link >
                <span className={styles.topnav} onClick={logOut}> Выйти</span>
            </div>
        ) : (
            <form onSubmit={handleSubmit} className={`${styles.auth}` + media}>
                <input
                    className={(badCredentials) ? (`${styles.input} ${styles.inputMail} ${styles.inputWrong}`) : (`${styles.input} ${styles.inputMail}`)}
                    type="text"
                    placeholder="Адрес электронной почты"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <div className={styles.secondRow}>
                    <input
                        className={(badCredentials) ? (`${styles.input} ${styles.inputPassword} ${styles.inputWrong}`) : (`${styles.input} ${styles.inputPassword}`)}
                        type="password"
                        placeholder="Пароль"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    {/* {desktop ? (<Link to={'/'} className={`${styles.passwordLink} ${basicStyles.textLink}`}>
                        Забыли пароль?
                    </Link>) : (<></>
                    )} */}
                    <button type="submit" className={`${styles.buttonSumbit} ${basicStyles.basicButton}`}>
                        Войти
                    </button>
                </div>
            </form>
        )}</>

    )
}

export default function AppHeader() {
    const [mobileOpen, changeMobileBar] = useState(false);
    const [isLoggedIn, setLogIn] = useState(false)
    const [isLoading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true)
        const token = loadToken();
        if (token) setLogIn(true)
        setLoading(false)
    }, []);
    return (
        <div id="header" className={styles.container}>
            <HeaderLoginContext.Provider
                value={{
                    isLoggedIn: isLoggedIn,
                    setLogIn: setLogIn,
                    changeMobileBar: changeMobileBar,
                    isLoading: isLoading
                }}>
                <div className={styles.menu}>
                    <Link to={'/'} className={styles.logo}>
                        <img
                            src='/images/header-logo.png'
                            width={'100%'}
                            alt={'DoctorOnBoard'}
                        />
                    </Link>
                    <Links desktop />
                    <Auth desktop />
                    <div className={styles.mobileBar}>
                        <img
                            src={mobileOpen ? '/images/close.png' : '/images/menu.png'}
                            className={styles.mobileBarButton}
                            width={25}
                            height={25}
                            alt={"menu"}
                            onClick={mobileOpen ? () => changeMobileBar(false) : () => changeMobileBar(true)}
                        /></div>
                </div>
                <div className={(mobileOpen) ? (
                    styles.fadeOut
                ) : (styles.fadeIn)}><Links />
                    <Auth />
                </div>
            </HeaderLoginContext.Provider>
        </div>
    )
}

