import styles from './press.module.css'
import basicStyles from '../../styles/basic.module.css'
const pressLinks = [{
    name: "VC.RU",
    link: "https://vc.ru/tribuna/116928",
    inactivePic: "/images/newMaterials/vcru.svg",
    activePic: "/images/newMaterials/vcru-hover.svg"
}, {
    name: "Medvestnik",
    link: "https://medvestnik.ru/content/news/Doctor-on-board-pomojet-vracham-dobitsya-udaleniya-negativnyh-otzyvov-o-sebe.html",
    inactivePic: "/images/newMaterials/medv.svg",
    activePic: "/images/newMaterials/medv-hover.svg"
}, {
    name: "Vademecum",
    link: "https://vademec.ru/news/2020/04/01/agentstvo-medcraft-zapustilo-servis-po-borbe-s-nedostovernymi-otzyvami-o-vrachakh/",
    inactivePic: "/images/newMaterials/vade.svg",
    activePic: "/images/newMaterials/vade-hover.svg"
}]

export default function PressSection() {
    return (
        <div className={styles.container}>
            <div className={basicStyles.heading}> СМИ о нас</div>

            <div className={styles.linkRow}>
                {pressLinks.map((link, index) => {
                    return (
                        <div key={"link" + index} className={(index % 2) ? `${styles.linkStyle} ${styles.linkStyleCenter}` : styles.linkStyle}>
                            <a href={link.link} target="_blank" rel="noreferrer">
                                <img src={link.inactivePic} className={styles.linkInactive} alt={link.name} />
                            </a>
                            <a href={link.link} target="_blank" rel="noreferrer">
                                <img src={link.activePic} className={styles.linkActive} alt={link.name} />
                            </a>
                        </div>
                    )
                })}
            </div>
        </div >
    )
}