import React, { useState } from "react";
import styles from './section.module.css'

export default function Dropdown({
    element,
    setElement,
    mandatory,
    possibleArray,
    label,
    arrayIndex,
    arrayKey,
    styleObject,
    disabled = false }) {
    let [dropdownActive, changeDropdownStatus] = useState(false);
    let defaultValue = "Выберите из списка"
    const compare = (a, b) => {
        let firstOne = false;
        let secondOne = false;
        if (a[arrayKey].startsWith(element)) {
            firstOne = true;
        }
        if (b[arrayKey].startsWith(element)) {
            secondOne = true;
        }
        if (firstOne && !secondOne) return -1

        if (!firstOne && secondOne) return 1
        if (element.length > 0) {
            if (a[arrayKey].length > b[arrayKey].length) {
                return 1
            }
            else {
                return -1
            }
        }
        else {
            return 1
        }
    }
    return (
        <div>
            <div className={styles.dropdownWrapper}>
                <div className={styleObject.dropdownInputWrapper}>
                    {(label) ? (
                        <div className={styles.fillerText}>{label}
                            {(mandatory) ? (<span className={styles.star}>{' *'}</span>) : (<></>)}
                        </div>) : (
                        <></>)}
                    <input
                        className={styleObject.dropdownInput + " " + styleObject.borderStyle}
                        type="text"
                        disabled={disabled}
                        placeholder={defaultValue}
                        value={element}
                        onChange={e => {
                            if (arrayIndex !== undefined) {
                                setElement(e.target.value, arrayIndex)
                            }
                            else setElement(e.target.value)
                        }}
                        onKeyPress={() => changeDropdownStatus(true)}
                        onClick={() => changeDropdownStatus(!dropdownActive)}
                        onBlur={() => {
                            setTimeout(() => changeDropdownStatus(false), 200)
                        }}
                    />
                    {(dropdownActive) ? (<div className={styleObject.dropdownArrowDown}>
                    </div>) : (<div className={styleObject.dropdownArrowUp}></div>)}
                </div>

                {dropdownActive ? (<div className={styleObject.dropdownList + ` ${styles.scrollable}`}>
                    {possibleArray.filter(e =>
                        e[arrayKey].toLowerCase().includes(element.toLowerCase())
                    ).sort(compare).map((e, i) => (
                        <div className={styleObject.listElement} key={"e" + e[arrayKey] + i} onClick={() => {
                            if (arrayIndex !== undefined) { setElement(e[arrayKey], arrayIndex) }
                            else { setElement(e[arrayKey]) } changeDropdownStatus(false)
                        }}>{e[arrayKey]}</div>
                    ))}

                </div>) : (<></>)}
            </div>
        </div>
    )
}
