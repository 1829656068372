import React, { useContext, useState } from "react";
import styles from '../user.module.css'
import { UserContext } from "../../../global/context"
import basicStyles from '../../../styles/basic.module.css'
import AcceptFileInput from '../../registrationSection/fileInput'
import sendRequest from "../../../global/api"

export default function DiplomaPanel({
    currentUser,
    setSaveSuccess,
    setUpdate }) {
    const [loadedDiploma, loadDiploma] = useState({});
    const UserFormInfo = useContext(UserContext);
    const { state, dispatch } = UserFormInfo;
    const deleteDiploma = async () => {
        const updateResponse = await sendRequest("UPDATE_DOCTOR", { doctor: state.id, diploma: null }, state.token)
        if (updateResponse.status === 200) {
            setSaveSuccess(true)
            const doctorResponse = await sendRequest("GET_DOCTOR", {}, state.token)
            const { diploma } = doctorResponse.data.results[currentUser]
            dispatch({
                type: "UPDATE",
                payload: {
                    diploma: decodeURI(diploma)
                }
            })
            loadDiploma({})
        }
        else {
            setSaveSuccess(false)
        }
        setUpdate(true)
    }
    const setDiploma = async () => {
        const updateResponse = await sendRequest("UPDATE_DOCTOR", { diploma: loadedDiploma }, state.token)
        if (updateResponse.status === 200) {
            setSaveSuccess(true)
            const doctorResponse = await sendRequest("GET_DOCTOR", {}, state.token)
            const { diploma } = doctorResponse.data.results[currentUser]
            dispatch({
                type: "UPDATE",
                payload: {
                    diploma: decodeURI(diploma)
                }
            })
            loadDiploma({})
        }
        else {
            setSaveSuccess(false)
        }
        setUpdate(true)
    }
    return (
        <>
            <div className={styles.userFullName}>
                Подтверждающие документы
            </div>
            <div className={styles.userDetailsDimplomaSection}>
                {(state.diploma.length > 0) ? (<>
                    {(state.verified === "CONFIRMING") ? (
                        <div className={styles.userDetailsDimplomaText}>
                            Вами был загружен файл диплома. Сейчас он находится на подтверждении. Вы можете просмотреть его или загрузить другой файл.
                        </div>
                    ) : (
                        <div className={styles.userDetailsDimplomaText}>
                            Загруженный Вами файл диплома был подтвержден.
                        </div>
                    )}
                    <div className={styles.userDetailsDimplomaPanel}>
                        <div className={styles.userDetailsParametersPanel}>
                            <div className={styles.userDetailsDimplomaParameterName}>
                                Название
                            </div>
                            <div className={styles.userDetailsDimplomaParameterSize}>
                                Подтверждение
                            </div>
                        </div>
                        <div className={styles.userDetailsValuesPanel}>
                            <div className={styles.userDetailsDimplomaParameterName}>
                                <a href={`${state.diploma}`} className={basicStyles.textLink}>
                                    {state.diploma.split('/').filter(string => string.includes('.pdf'))}
                                </a>
                            </div>

                            {(state.verified === "CONFIRMING") ? (
                                <div className={styles.userDetailsDimplomaParameterSize}>
                                    Ожидает подтверждения
                                    <div className={styles.userDetailsDimplomaDelete}>
                                        <img
                                            src="/images/close.png"
                                            className={styles.deleteButton}
                                            alt={"delete this"}
                                            onClick={() => deleteDiploma()}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.userDetailsDimplomaParameterSize}>
                                    Подверждено
                                </div>
                            )}

                        </div>
                    </div>
                </>) : (<div className={styles.userDetailsDimplomaText}>
                    Вы еще не загрузили файл диплома. Прикрепите файл с Вашим дипломом в находящемся ниже окне.
                </div>)}
                {(state.verified === "CONFIRMING") ? (
                    <>
                        <AcceptFileInput
                            format='application/pdf'
                            text="Нажмите на данное поле, либо перетащите в него файл, чтобы загрузить диплом. Загружайте Ваш диплом в формате PDF."
                            setFile={loadDiploma}

                            inputId='user-upload'
                            styleObject={{
                                wrapper: styles.userDetailsDropzone,
                                heading: styles.userDropzoneLabel,
                                content: styles.userDropzoneText,
                                dropzone: ""
                            }} />
                        {(loadedDiploma.name) ?
                            (<>
                                <div className={styles.userInfoLabel} >Загружен файл:</div>
                                <div >{loadedDiploma.name}</div>
                                <button className={styles.changeButton + " " + styles.saveButton} onClick={() => {
                                    setDiploma()
                                }}>
                                    Загрузить на сайт
                                </button>
                            </>) : (<></>)}
                    </>
                ) : (<></>)}
            </div>
        </>
    )
}