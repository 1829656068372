import React, { useState, useReducer } from "react";
export const HeaderLoginContext = React.createContext();
export const UserContext = React.createContext();

export const defaultUserInfo = {
    first_name: "",
    last_name: "",
    maiden_name: "",
    middle_name: "",
    city: "",
    specialty: [""],
    phone: [""],
    email: "",
    extra_email: "",
    stage: 1,
    diploma: ""
}
const UserContextProvider = ({ children }) => {
    function reducer(state, action) {
        switch (action.type) {
            case "UPDATE":
                return {
                    ...state,
                    ...action.payload,
                };
            case "STEP_FORWARD":
                return {
                    ...state,
                    ...action.payload,
                    stage: state.stage + 1
                };
            case "STEP_BACK":
                return {
                    ...state,
                    stage: state.stage - 1
                };
            default: {
                return state;
            }
        }
    }
    const [state, dispatch] = useReducer(reducer, {
        first_name: "",
        last_name: "",
        maiden_name: "",
        middle_name: "",
        city: "",
        specialty: [""],
        phone: [""],
        email: "",
        extra_email: "",
        stage: 1,
        diploma: ""
    });
    let [validationObject, validate] = useState({
        first_name: true,
        last_name: true,
        city: true,
        specialty: true,
        phone: [true],
        email: true,
        extra_email: true,

    });
    const changeArray = (element, index, array, key, setArray) => {
        let arrayCopy = [...array];
        arrayCopy[index] = element;
        setArray({ type: "UPDATE", payload: { [key]: arrayCopy } })
    }
    const addToArray = (array, setArray, key, value = "") => {
        let arrayCopy = [...array];
        arrayCopy.push(value);
        setArray({ type: "UPDATE", payload: { [key]: arrayCopy } })
    }
    const deleteFromArray = (array, setArray, key) => {
        let arrayCopy = [...array];
        arrayCopy.splice(arrayCopy.length - 1, 1);
        setArray({ type: "UPDATE", payload: { [key]: arrayCopy } })
    }
    const addToValidator = (key) => {
        let objectCopy = { ...validationObject };
        objectCopy[key].push(true);
        validate(objectCopy)
    }
    const deleteFromValidator = (key) => {
        let objectCopy = { ...validationObject };
        objectCopy[key].splice(objectCopy[key].length - 1, 1);
        validate(objectCopy)
    }
    return (
        <UserContext.Provider value={{
            state: state,
            validationObject: validationObject,
            validate: validate,
            dispatch: dispatch,
            functions: {
                changeArray,
                addToArray,
                deleteFromArray,
                addToValidator,
                deleteFromValidator
            }
        }}>
            {children}
        </UserContext.Provider>
    );
};

export const reducerActions = {
    UPDATE: "UPDATE",
    STEP_FORWARD: "STEP_FORWARD",
    STEP_BACK: "STEP_BACK"
}
export { UserContextProvider };