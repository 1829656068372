import React, { useContext, useState, useEffect } from "react";
import styles from './section.module.css'
import RegistrationFirstPage from './registrationFirstPage'
import RegistrationSecondPage from './registrationSecondPage'
import RegistrationThirdPage from './registrationThirdPage'
import { UserContext, reducerActions } from "../../global/context"
import { loadToken } from "../../global/token"
import RegistrationPopup from './registrationPopup'

export default function RegistrationForm({ constants }) {
    const [isLoading, setLoading] = useState(true)
    const [popup, setPopup] = useState(false);
    const [popupContent, setPopupContent] = useState({ heading: "", text: "" });
    const UserFormInfo = useContext(UserContext);
    const { state, dispatch } = UserFormInfo;
    let { stage } = state;
    useEffect(() => {
        setLoading(true)
        const token = loadToken();
        if (token) dispatch({ type: reducerActions.UPDATE, payload: { stage: 4 } })
        setLoading(false)
    }, []);
    return (
        <>
            {(isLoading) ? (<div className={`${styles.sectionPanelDefault} ${styles.registrationForm} `}></div>) : (<div className={`${styles.sectionPanelDefault} ${styles.registrationForm} ${styles.scrollable}`}>
                {(stage < 4) ? (<>
                    <div className={`${styles.blueText} ${styles.heading}`}>Регистрация</div>
                </>) : (<div className={`${styles.blueText} ${styles.heading}`}>Спасибо за регистрацию</div>)}
                {(stage === 4) ? (<div className={`${styles.sectionText} ${styles.fillerText}`}>Мы отправим отзывы о Вас на указанный Вами email</div>) : (<></>)}
                {(stage === 1) ? (
                    <RegistrationFirstPage />
                ) : (<></>)}
                {(stage === 2) ? (
                    <RegistrationSecondPage constants={constants} />) : (<></>)}
                {(stage === 3) ? (
                    <RegistrationThirdPage
                        setPopup={setPopup}
                        setPopupContent={setPopupContent} />) : (<></>)}

            </div>)}
            <RegistrationPopup
                status={popup}
                setPopup={setPopup}
                popupContent={popupContent}
            />
        </>
    )
}
