import React, { useState, useContext } from "react";
import { validateNames } from '../validators'
import styles from '../section.module.css'
import { UserContext, reducerActions } from "../../../global/context"
import { capitalize } from "../../../global/utils"
import basicStyles from "../../../styles/basic.module.css"
import sendMetricsEvent from "../../../global/analytics"



export default function RegistrationFirstPage() {
    const registrationFormInfo = useContext(UserContext);
    const { state, validationObject, validate, dispatch } = registrationFormInfo;
    const { first_name, last_name, maiden_name, middle_name } = state;
    let isMaidenNameOn = false;
    if (maiden_name.length) isMaidenNameOn = true;
    let [maidenNameOn, setMaidenNameOn] = useState(isMaidenNameOn);
    const checkField = (field) => {
        let string = `${styles.doctorInput}`
        if (!field) string += ` ${styles.errorBorder}`
        else string += ` ${styles.standardBorder}`
        return string
    }
    const handler = () => {
        const isFirstNameValid = validateNames(first_name);
        const isLastNameValid = validateNames(last_name);
        validate(prev => (
            {
                ...prev,
                first_name: isFirstNameValid,
                last_name: isLastNameValid
            }))
        if (isLastNameValid && isFirstNameValid) {
            sendMetricsEvent('registration', 'registrationStepOne')
            dispatch({
                type: reducerActions.STEP_FORWARD,
                payload: {
                    first_name: capitalize(first_name),
                    last_name: capitalize(last_name),
                    maiden_name: capitalize(maiden_name),
                    middle_name: capitalize(middle_name)
                }
            })
        }
    }
    return (
        <>
            <div className={styles.progress}><div className={styles.counter}>1/3</div><div className={styles.progressText}>Чтобы найти отзывы о Вас</div></div>
            <div className={styles.inputTab}>
                <div className={styles.inputSection}>
                    <div className={styles.fillerText}>Фамилия<span className={styles.star}>{' *'}</span></div>
                    <input
                        className={checkField(validationObject.last_name)}
                        type="text"
                        placeholder="Укажите Вашу фамилию"
                        value={last_name}
                        onChange={e => dispatch({
                            type: reducerActions.UPDATE,
                            payload: { last_name: e.target.value }
                        })}
                    />
                    {(!maidenNameOn) ? (<div className={basicStyles.textButton}>{"+"}<span className={basicStyles.textLink} onClick={() => setMaidenNameOn(true)}>{'Добавить девичью фамилию'}</span></div>) : (<></>)}
                </div>
                {(maidenNameOn) ? (
                    <div className={styles.inputSection}>
                        <div className={styles.fillerText}>Девичья фамилия</div>
                        <input
                            className={`${styles.doctorInput} ${styles.standardBorder} `}
                            type="text"
                            placeholder="Укажите Вашу девичью фамилию"
                            value={maiden_name}
                            onChange={e => dispatch({
                                type: "UPDATE",
                                payload: { maiden_name: e.target.value }
                            })}
                        /></div>) : (<></>)}

                <div className={styles.inputSection}>
                    <div className={styles.inputSection}>
                        <div className={styles.fillerText}>Имя<span className={styles.star}>{' *'}</span></div>
                        <input
                            className={checkField(validationObject.first_name)}
                            type="text"
                            placeholder="Укажите Ваше имя"
                            value={first_name}
                            onChange={e => dispatch({
                                type: reducerActions.UPDATE,
                                payload: { first_name: e.target.value }
                            })}
                        />
                    </div>

                    <div className={styles.inputSection}>
                        <div className={styles.fillerText}>Отчество</div>
                        <input
                            className={`${styles.doctorInput} ${styles.standardBorder} `}
                            type="text"
                            placeholder="Укажите Ваше отчество"
                            value={middle_name}
                            onChange={e => dispatch({
                                type: reducerActions.UPDATE,
                                payload: { middle_name: e.target.value }
                            })}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.buttonRow}>
                <div className={`${basicStyles.basicButton} ${styles.buttonNext}`} onClick={() => handler()}><span>Далее</span></div>
            </div>
        </>

    )
}