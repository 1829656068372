let url = ""
let domain = ""

if (process.env.NODE_ENV === 'development') {
    url = 'http://127.0.0.1:8000/api/v1'
}
if (process.env.NODE_ENV === 'production') {
    url = 'https://doctoronboard.ru/api/v1'
}
export const API_ROOT = url
export const DOMAIN_ROOT = domain
