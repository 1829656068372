import { possibleCities } from "../../global/constants"
export const phoneMask = (event) => {
    const input = event.target.value;
    const regex = /\D/g;
    let selectionStart = event.target.selectionStart;
    if (event.target.value === "+") {
        if (event.nativeEvent.inputType !== ("deleteContentBackward" || "deleteContentForward")) {
            return {
                value: "+",
                position: selectionStart
            };
        }
    }
    let onlyNumbersValue = input.replace(regex, '');
    let formattedInputValue = onlyNumbersValue;
    if (["7", "8", "9"].indexOf(onlyNumbersValue[0]) > -1) {
        if (onlyNumbersValue[0] === "9") {
            onlyNumbersValue = "7" + onlyNumbersValue;
            selectionStart = selectionStart + 2;
        }
        if (onlyNumbersValue.length > 1) formattedInputValue = onlyNumbersValue[0] + "(" + onlyNumbersValue.slice(1);
        if (onlyNumbersValue.length > 4) formattedInputValue = formattedInputValue.slice(0, 5) + ")" + formattedInputValue.slice(5);
        if (onlyNumbersValue.length > 7) formattedInputValue = formattedInputValue.slice(0, 9) + "-" + formattedInputValue.slice(9);
        if (onlyNumbersValue.length > 9) formattedInputValue = formattedInputValue.slice(0, 12) + "-" + formattedInputValue.slice(12);

        if (formattedInputValue[0] === "7") {
            formattedInputValue = "+7" + formattedInputValue.slice(1);
            formattedInputValue = formattedInputValue.substring(0, 16)
        }

        else {
            formattedInputValue = formattedInputValue.substring(0, 15)


        }
        if (regex.test(formattedInputValue[selectionStart - 1])) {

            if (event.nativeEvent.inputType === ("deleteContentBackward" || "deleteContentForward")) {
                selectionStart = selectionStart - 1;
            }
            else {
                selectionStart = selectionStart + 1;
            }
        }
    } else {
        if (onlyNumbersValue.length) {
            formattedInputValue = '+' + onlyNumbersValue.substring(0, 15);

        }
        if (onlyNumbersValue.length === 1) {
            selectionStart = selectionStart + 1;
        }

    }
    return {
        value: formattedInputValue,
        position: selectionStart
    };
}
export function justFormatNumbersIn(input) {
    const regex = /\D/g;
    const onlyNumbersValue = input.replace(regex, '');
    let formattedInputValue = onlyNumbersValue;
    if (onlyNumbersValue.length > 1) formattedInputValue = onlyNumbersValue[0] + "(" + onlyNumbersValue.slice(1);
    if (onlyNumbersValue.length > 4) formattedInputValue = formattedInputValue.slice(0, 5) + ")" + formattedInputValue.slice(5);
    if (onlyNumbersValue.length > 7) formattedInputValue = formattedInputValue.slice(0, 9) + "-" + formattedInputValue.slice(9);
    if (onlyNumbersValue.length > 9) formattedInputValue = formattedInputValue.slice(0, 12) + "-" + formattedInputValue.slice(12);
    if (formattedInputValue[0] === "7") formattedInputValue = "+" + formattedInputValue;
    return formattedInputValue
}
export function justFormatNumbersOut(input) {
    const regex = /\D/g;
    const onlyNumbersValue = input.replace(regex, '');
    let formattedInputValue = onlyNumbersValue;
    if (formattedInputValue[0] === "7") formattedInputValue = "+" + formattedInputValue;
    return formattedInputValue
}
export function validateSpecialties(array) {
    return array[0].length > 0
}
export function validateEmails(variable) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(variable.toLowerCase())
}
export function validatePhones(variable) {
    const regex = /^[0-9]{11,15}$/
    const validatedArray = variable.map(e => regex.test(e))
    return validatedArray;
}
export function validateCity(variable) {
    return possibleCities.some(c => c.city.toLowerCase() === variable.toLowerCase())
    // return variable.length > 0
}
export function validateNames(variable) {
    return variable.length > 0
}