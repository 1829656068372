
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';

export default function sendMetricsEvent(category, event) {
    console.log(event)
    ReactGA.event({
        category: category,
        action: event
    });
    ym('reachGoal', event);
}