// import React, { useState, useEffect, useReducer } from "react";
import React, { useState, useEffect } from "react";
import RegistrationSection from '../registrationSection'
import SecondSection from '../secondSection'
import PressSection from '../pressSection'
import ReviewSection from '../reviewSection'
import { getUpdatedSpecialtiesList } from '../../global/api'

export default function Home() {
  const [constants, setConstants] = useState({})
  useEffect(async () => {
    let possibleSpecialties = await getUpdatedSpecialtiesList()
    setConstants({
      possibleSpecialties: possibleSpecialties
    })
  }, []);

  return (
    <>
      <RegistrationSection constants={constants} />
      <SecondSection />
      <PressSection />
      <ReviewSection />
    </>
  )
}