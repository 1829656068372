import React from "react";
import ScrollLink from '../scrollLink';
import basicStyles from "../../styles/basic.module.css"
import styles from './section.module.css'
import RegistrationForm from './registrationForm'

export default function RegistrationSection({ constants }) {
    return (
        <div id="registration" className={styles.container}>
            <div className={styles.backgroundPic}></div>
            <div className={styles.mobilePanel}>
                <div className={styles.infoPanelMini}>
                    <div>Уважаемый коллега, с помощью сервиса DoctorOnBoard Вы вовремя узнаете о негативных постах в соцмедиа и СМИ, а также оперативно поблагодарите ваших пациентов за положительные отзывы.</div>
                    <ScrollLink scrollTo="about" className={styles.mobileButton}>Подробнее
                        <div className={styles.plane}><img src="/images/airplane.png" height={20} width={20} alt="airplane" /></div>
                    </ScrollLink>
                </div>
                <img src="/images/doctor.png" className={styles.doctorPicMini} alt={"Фото доктора"} />
            </div>
            <div className={`${styles.sectionPanelDefault} ${styles.infoPanel}`}>
                <div className={styles.heading}>DoctorOnBoard</div>
                <div className={`${styles.sectionText} ${styles.fillerText}`}>Уважаемый коллега, с помощью сервиса DoctorOnBoard Вы вовремя узнаете о негативных постах в соцмедиа и СМИ, а также оперативно поблагодарите ваших пациентов за положительные отзывы.</div>
                <div className={`${styles.sectionText} ${styles.fillerText}`}>DoctorOnBoard мониторит упоминания во всех ведущих социальных сетях, а также специализированных сайтах по сбору отзывов о врачах, и позволяет вам контролировать свою репутацию онлайн.</div>
                <div className={`${styles.sectionText} ${styles.fillerText}`}>Получайте отчёты на Ваш емайл бесплатно.</div>
                <ScrollLink scrollTo="about" className={`${basicStyles.basicButton} ${styles.buttonInfo}`}>Подробнее</ScrollLink>
            </div>
            <img className={styles.doctorPic} src="/images/doctor.png" alt={"Фото доктора"} />
            <RegistrationForm constants={constants} />
        </div >
    )
}